import React, { Suspense } from "react";
// @ts-nocheck
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

// import OlbiaInLoco from "./pages/OlbiaInLoco";

const Home = React.lazy(() => import("./pages/Home"));
const Olbia = React.lazy(() => import("./pages/Olbia"));
const Conceicao = React.lazy(() => import("./pages/Conceicao"));
const Morador = React.lazy(() => import("./pages/Morador"));
const Proprietario = React.lazy(() => import("./pages/Proprietario"));
const Investidor = React.lazy(() => import("./pages/Investidor"));
const OlbiaInLoco = React.lazy(() => import("./pages/OlbiaInLoco"));

// import Home from "./pages/Home";
// import Olbia from "./pages/Olbia";
// import Conceicao from "./pages/Conceicao";
// import Morador from "./pages/Morador";
// import Proprietario from "./pages/Proprietario";
// import Investidor from "./pages/Investidor";
const Rotas = () => (
  <BrowserRouter>
    <ScrollToTop>
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/morador" element={<Morador />} />
          <Route path="/proprietario" element={<Proprietario />} />
          <Route path="/investidor" element={<Investidor />} />
          <Route path="/olbia-in-loco" element={<OlbiaInLoco />} />
          <Route path="/olbia" element={<Olbia />} />
          <Route path="/conceicao" element={<Conceicao />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  </BrowserRouter>
);

export default Rotas;
